<template>
  <div class="CurentMessage">
    <div class="leftConent">
      <h1>今日资讯</h1>
      <div class="leftCon">
        <div
          class="TodayInfo"
          v-for="(item, index) in list.InfoList"
          :key="index"
          @click="jumpInfoDetail(item.id)"
        >
          <div class="TodayInfoLeft">
            <img
              src="../assets/bg.png"
              alt=""
            />
          </div>
          <div class="TodayInfoRight">
            <h2>{{ item.title }}</h2>
            <p>{{ item.description }}</p>
            <div class="Toady_bottom">
              <span><strong class="el-icon-time"></strong>{{ item.beginDate }}</span>
              <span
                ><strong class="el-icon-view"></strong
                ><strong>{{ item.pviews == -1 ? "222" : item.pviews }}</strong
                >人阅读</span
              >
            </div>
          </div>
          <div class="clear"></div>
          <el-divider></el-divider>
        </div>
      </div>
    </div>
    <div class="rightConent">
      <h1>平台公告</h1>
      <div class="rightCon">
        <div class="PlatformNotice">
          <div
            class="NoticeBox"
            v-for="(item, index) in list.NoticeList"
            :key="index"
            @click="jumpInfoDetail(item.id)"
          >
            <h2>{{ item.title }}</h2>
            <p>{{ item.description }}</p>
            <div class="NoticeBox_bottom">
              <span><strong class="el-icon-time"></strong>{{ item.beginDate }}</span>
              <span
                ><strong class="el-icon-view"></strong
                ><strong>{{ item.pviews == -1 ? "222" : item.pviews }}</strong
                >人阅读</span
              >
            </div>
            <el-divider></el-divider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.clear {
  clear: both;
}
.CurentMessage {
  margin: 20px 0;
  float: left;
  width: 100%;
  h1 {
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  .leftConent {
    width: 64%;
    margin-right: 15px;
    float: left;
    .leftCon {
      padding: 30px;
      background: #ffffff;
      .TodayInfo {
        .TodayInfoLeft {
          border: 1px solid #f0f0f0;
          width: 188px;
          height: 168px;
          float: left;
          img {
            width: 100%;
            height: auto;
            margin-top: 25px
          }
        }
        .TodayInfoRight {
          height: 174px;
          margin-left: 15px;
          width: calc(100% - 205px);
          float: left;
          position: relative;
          h2 {
            color: #333333;
            font-size: 16px;
            font-weight: bold;
            line-height: 30px;
          }
          p {
            font-size: 13px;
            color: #999999;
          }
          .Toady_bottom {
            position: absolute;
            bottom: 0;
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            color: #999999;
            span {
              padding-right: 10px;
              i {
                padding-right: 7px;
              }
            }
          }
        }
      }
    }
  }
  .rightConent {
    width: calc(36% - 15px);
    float: left;
    .rightCon {
      padding: 30px;
      background: #ffffff;
      .PlatformNotice {
        .NoticeBox {
          h2 {
            color: #333333;
            font-size: 16px;
            font-weight: bold;
            line-height: 30px;
          }
          p {
            font-size: 13px;
            color: #999999;
          }
          .NoticeBox_bottom {
            margin-top: 30px;
            height: 30px;
            line-height: 30px;
            color: #999999;
            font-size: 12px;
            span {
              padding-right: 10px;
              i {
                padding-right: 7px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { articleList } from "api/product.js";

export default {
  name: "CurrentMessage",
  data() {
    return {
      pullRefreshss: true,
      scollY: null,
      current: 1, //	当前页
      size: 5, //	每页的数量
      total: 0,
      list: {
        InfoList: "",
        NoticeList: [],
      },
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.getNatice();
    this.getInfo();
  },

  methods: {
    handleScroll() {
      const getScrollTop = document.documentElement.scrollTop; //获取滚动条当前的位置
      const getWindowHeight = document.documentElement.clientHeight; //获取当前可视范围的高度
      const getScrollHeight = document.documentElement.scrollHeight; //获取文档完整的高度
      this.scollY = getScrollTop + getWindowHeight - getScrollHeight;
      if (this.scollY >= -50) {
        if (!this.pullRefreshss) {
          return false;
        }
        if (this.size > this.total) {
          return false;
        } else {
          this.size = this.size + 5; //显示条数新增
          this.getInfo(); //请求列表list 接口方法
          this.pullRefreshss = false;
        }
      } else {
        this.pullRefreshss = true;
        return false;
      }
    },

    jumpInfoDetail(infoId) {
      sessionStorage.setItem("infoId", infoId);
      this.$router.push({ name: "CurrentMessageDetail" });
    },
    getInfo() {
      const infoParams = {
        type: 1,
        current: 1, //	当前页
        size: this.size, //	每页的数量
      };
      // 文章列表
      articleList(infoParams).then((res) => {
        const infoArray = res.data.data.records;
        this.$set(this.list, "InfoList", infoArray);
        this.total = res.data.data.total;
      });
    },
    getNatice() {
      const noticParams = {
        type: 0,
        current: 1, //	当前页
        size: 10, //	每页的数量
      };
      // 公告列表
      articleList(noticParams).then((res) => {
        const noticeArray = res.data.data.records;
        this.$set(this.list, "NoticeList", noticeArray);
      });
    },
  },
  created() {},
};
</script>
